const portfolioOne =
  "https://dm2306files.storage.live.com/y4mX3ZEA84eSJPoKWtMB8ISkCIXhoI3ea3d1gJmuZ-lwDPHVEGXFfpOoEB5W9ZATjpFJO8DgEfr-FiMXW1bK0zftXZusYdA1HgUMv-KRQOVMw3OVY8_17mIFVLLczCYucBqkFXUf7OhMec35pXFAEly6beW-Bh3nfFLh6jOdJ20o-Kql8UqYmXqOvz6M4YWOp05?width=1600&height=902&cropmode=none";
const portfolioTwo =
  "https://dm2306files.storage.live.com/y4mehooU-ecmg-XERX1B-J_bew3_UX8aEFpBdujLRyGlwIiZNOwKgd_E93s66zCDSGbDQV7yRMXEvw6VGudQPClbv_sgpsN_hU592rLcq4-5N1IoAGJWtnzf3t0evUGITKhDrN8o2G6EsKXcebxDDs5NlFqX1r6JnJQfbjKtWf-NFsopUyZml9d_g4yyVbaTSsJ?width=1600&height=900&cropmode=none";
const portfolioThree =
  "https://dm2306files.storage.live.com/y4mV5DCwarvrVKPdUrSrNqD7XL49piHv6N8IfInpiE1Cb1VOnwLeSlI49unzJxU_q6UOv2E-gcK7KZqQIcmMcq6URA3qXXy2h44ILKESs2p_NYysdwqOVdxf7l1kAUZfNs_dmx7E3vBxPeWU-AyrVd_RaGZuHlasRKhULDPFoHpHNM-mvRcAmQ9PRr2Bm1-rECq?width=1600&height=900&cropmode=none";
const portfolioFour =
  "https://dm2306files.storage.live.com/y4mpMyEnZdNAjVR2nphXOBawxXLeirY_ClH3BVhXC_ZxdQtEQhZgiYFOTfy3FBxVBq6_B1L_WKn4XdQhrBCOWtt4_XTaf58OnYNqOmfZymsmgFpWPK4VFmAmanDzXWC5rb2uX8eCCZANoCP3igicm16-M0-jZa5f26MpeMZxEtzX91-LdYpNLixbgSySnmJA-eu?width=1600&height=900&cropmode=none";
const portfolioFive =
  "https://dm2306files.storage.live.com/y4mrwwO4YlWM4Ei4O96vHcZgFYy8n-g0IJpE0D2djdUYRJ7V2qW-Y5vyVY6OjcRt3LFkWO1CXTE2aBPLQFqSKdxycAdYalbCknicmZf2Yzss54hMBWRgPTVt1tWk4SZ2m8W0sSoy5IaqJymwys2QrJOsD9pwhaDqKwOtaDu892f0FejYPD7mN1YEfiTcheijvmv?width=1600&height=900&cropmode=none";
const portfolioSix =
  "https://dm2306files.storage.live.com/y4mhfyfEKc54RKgF-QQfguQbENEW5BrQk69tRIyE227ZLRBKUoEdJ6vrSiYx8E-vPizoGh-Okanw8YqH6oSHz4Z7VXVbhmVcl_8ye-YY2bDE3uy9aKuv6Ag7UKegkrchK7JAeYg3oRCBhrUlPQii-Lglkop5jsgdnmqSYwCVhgn2NZU3r4njT8AZNVcRMkx7FWo?width=1629&height=900&cropmode=none";
const portfolioSeven =
  "https://dm2306files.storage.live.com/y4my9ZCKZuJcR1idpHnQRKsJn6jbpONdH8wyG0LvI01G-AQuVw8hR71WcOoK__EQAMcqLosHKyWN0CNnO14YzGUno1d95rTLqTRA4X_ViRolk4wL6_CIZbR33xxHxg-cN9t5NBcHbGYH6SOY_WpKLOdRgBlKqGHaWxyEqk5wPIo522KMNOtdbYsXA3UtGEtSeRp?width=1596&height=906&cropmode=none";
const portfolioEight =
  "https://dm2306files.storage.live.com/y4mKjTt-BNDDMEm2nsv4qX_5LX4heHWZ8Fi9odfwyLb91AdSbdK44ho_HGJBYhmLyY9wk7dYNqVr7KBFJYn4PT8WirY7PFekUpTR8SqAtBzLlkqAyRPT1AnsTMr6Wj8bi0n2-XRAtfIsyvVkK92qLo-iD_Y4ewT2HOAdD11nnUEhWX1qx-bSehEHOWojjpWEKqT?width=1600&height=897&cropmode=none";
const portfolioNine =
  "https://dm2306files.storage.live.com/y4ma6sj4ocZHIDAeFiaHjfOwwF-NQd9ayibJDhnEb02_s5OZu-7XiB5FUObOSN5x_tGLNDxDes1p0QGxWj9o6Rg93XxyzUrMy1RdtzW1A3w3RM7M2ehkAV9tibA_q-5o78k6mFJWy-GYOlW9BRNN8ttnPX_gZLzjUK_wgPdu_PQ9tPHDrQ8YYumSFfw0UFu4YPL?width=1600&height=905&cropmode=none";
const portfolioTen =
  "https://dm2306files.storage.live.com/y4m8pHMgjw2RWq5aNrEVSfY7FMMWOfNc85Q2NoSmsqPolq5NXrM-82GyOo19fTAX5Ncc5DfQr7rHSMfLLlrhV4FDQxNnFpeyrFhNpOUZs7alP8Ve_k9asKqgOihinTc9dG-7JFO-1y0mJbZaGJWlSSHiP5uawJna2KEyqs1TKxz-fbZwhB_u9ZqmZtsNx4DU9c_?width=1600&height=900&cropmode=none";

class PortfolioDetail {
  constructor() {
    this.images = [
      {
        image: portfolioOne,
        title: "Navigating Glaciers",
        subTitle: "To Penguin Country",
        slideLocation: 0,
      },
      {
        image: portfolioTwo,
        title: "Close Encounters",
        subTitle: "Of the Penguin Kind",
        slideLocation: 1,
      },
      {
        image: portfolioThree,
        title: "Penguin Community",
        subTitle: "At Feeding Sites",
        slideLocation: 2,
      },
      {
        image: portfolioFour,
        title: "The Circle of Life",
        subTitle: "With Penguin Babies",
        slideLocation: 3,
      },
      {
        image: portfolioFive,
        title: "Penguin Journeys",
        subTitle: "Hundreds of Miles",
        slideLocation: 4,
      },
      {
        image: portfolioSix,
        title: "Solo Encounters",
        subTitle: "With a Communal Bird",
        slideLocation: 5,
      },
      {
        image: portfolioSeven,
        title: "Growing Up Penguin",
        subTitle: "Shelter and Support",
        slideLocation: 6,
      },
      {
        image: portfolioEight,
        title: "Penguin Games",
        subTitle: "For Survival Itself",
        slideLocation: 7,
      },
      {
        image: portfolioNine,
        title: "Penguin Heights",
        subTitle: "Incredible Destinations",
        slideLocation: 8,
      },
      {
        image: portfolioTen,
        title: "Antarctica Summer",
        subTitle: "Warmer, Less Snow",
        slideLocation: 9,
      },
    ];

    this.elements = [
      {
        href: portfolioOne,
        type: "image",
      },
      {
        href: portfolioTwo,
        type: "image",
      },
      {
        href: portfolioThree,
        type: "image",
      },
      {
        href: portfolioFour,
        type: "image",
      },
      {
        href: portfolioFive,
        type: "image",
      },
      {
        href: portfolioSix,
        type: "image",
      },
      {
        href: portfolioSeven,
        type: "image",
      },
      {
        href: portfolioEight,
        type: "image",
      },
      {
        href: portfolioNine,
        type: "image",
      },
      {
        href: portfolioTen,
        type: "image",
      },
    ];
  }
}

export default PortfolioDetail;
